import { useState, useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";

import classNames from "classnames";
import {
	Column,
	Toast,
	Button,
	Dialog,
	TabView,
	TabPanel,
	addLocale,
	locale,
	Tag,
	AvatarGroup,
	Avatar,
} from "../../components/crud";
import { Conexion } from "../../service/Conexion";
import {
	TablaDatos,
	BarraSuperior,
	EliminarVentana,
	productDialogFooter,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setDataSet, setFormData } from "../../store/appSlice";
import { Cargando } from "../../components/crud/Cargando";
import { DayCalendar } from "./DayCalendar";
// import { ImagenCampo } from "../../components/crud/ImagenCampo";

export const AgendaPersona = () => {
	const TABLA = "agendamientoagenda";
	let emptyFormData = {};
	const { dataSet, formData } = useSelector((state) => state.appsesion); //datos el storage redux
	const dispatch = useDispatch();
	const [productDialog, setProductDialog] = useState(false);
	const [deleteProductDialog, setDeleteProductDialog] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const toast = useRef(null);
	const [recargar, setrecargar] = useState(0);
	const [cargando, setCargando] = useState(false);
	const datatable = new Conexion();
	// const [valueDropTipoEventos, setvalueDropTipoEventos] = useState(null);
	// const [valueDropUbicacion, setvalueDropUbicacion] = useState(null);
	// const [valueDropUbicacionfilter, setvalueDropUbicacionfilter] = useState(
	// 	null
	// );
	// const [valueDropLugares, setdropdowlugares] = useState(null);
	// const [valueDropHora, setvalueDropHora] = useState(null);
	// const [valueDropMinuto, setvalueDropMinuto] = useState(null);
	// const [valueOpcionsacreditados, setvalueOpcionsacreditados] = useState(
	// 	null
	// );
	// const [valueOpcionsaAgendados, setvalueOpcionsaAgendados] = useState([]);

	// const [validHora, setvalidHora] = useState(false);
	// const [selectedAcreditados, setselectedAcreditados] = useState(null);
	// const [lugarDisponible, setlugarDisponible] = useState(true);
	// const [personasAgenda, setpersonasAgenda] = useState([]);
	const [cardseleccionados, setcardseleccionados] = useState([]);
	const [listadia, setlistadia] = useState([]);

	addLocale("es", {
		firstDayOfWeek: 1,
		dayNames: [
			"domingo",
			"lunes",
			"martes",
			"miércoles",
			"jueves",
			"viernes",
			"sábado",
		],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
		monthNames: [
			"enero",
			"febrero",
			"marzo",
			"abril",
			"mayo",
			"junio",
			"julio",
			"agosto",
			"septiembre",
			"octubre",
			"noviembre",
			"diciembre",
		],
		monthNamesShort: [
			"ene",
			"feb",
			"mar",
			"abr",
			"may",
			"jun",
			"jul",
			"ago",
			"sep",
			"oct",
			"nov",
			"dic",
		],
		today: "Hoy",
		clear: "Limpiar",
	});

	locale("es");


	useEffect(() => {
		//cargar la data total
		setCargando(true);
		dispatch(setFormData(emptyFormData));

		datatable.gettable(TABLA).then((data) => {
			dispatch(setDataSet(data));
			setCargando(false);
			// console.log({data});
		});
		setcardseleccionados([]);
	}, [recargar]);

	const hideDialog = () => {
		setSubmitted(false);
		setProductDialog(false);
	};

	const hideDeleteProductDialog = () => {
		setDeleteProductDialog(false);
	};

	const editProduct = (id) => {
		setCargando(true);
		datatable
			.getItem("agendamientoagendalista", id)
			.then((data) => {
				console.log('lista dias ', data);
				setlistadia( data )
			});
			
			console.log(listadia);
		setProductDialog(true);
		setCargando(false);
	};

	/*eventos*/

	/* validaciones de campos */
	const onInputChange = (e, name) => {
		// console.log(e.target, e.target.value, name);
		const val = (e.target && e.target.value) || "";
		let _product = { ...formData };
		_product[`${name}`] = val;
		// console.log(_product);
		dispatch(setFormData(_product));
	};

	const onInputChangeDate = (e, name) => {
		const val = e.value.toISOString().split("T")[0] || "";
		let _product = { ...formData };
		_product[`${name}`] = val;
		dispatch(setFormData(_product));
	};

	const actionBodyTemplate = (rowData) => {
		console.log(rowData?.usu_reg)
		console.log('leo');

		return (
			<div
				className='actions'
				style={{
					display: "flex",
				}}>
				 <Button
					icon='pi pi-sitemap'
					className='p-button-rounded p-button-success mr-2'
					

					onClick={() => window.open(`https://www.bogotamarket.com/agenda1/${rowData?.usu_reg}` )}
					// onClick={() => window.open(`/agendapersona/${rowData.id}` )}
				/>
				{/*<Button
					icon='pi pi-trash'
					className='p-button-rounded p-button-warning mr-2'
					onClick={() => confirmDeleteProduct(rowData)}
				/> */}
				<Button
					icon='pi pi-search'
					className='p-button-rounded p-button-warning mr-2'
					onClick={() => editProduct(rowData.id)}
				/>
			</div>
		);
	};

	const tagBodyTemplate = (rowData) => {
		return (
			<Tag
				rounded
				className='9-1'
				style={{ background: `${rowData.col_tag}`, color: "white" }}
				value={`${rowData.nom_tag}`}></Tag>
		);
	};

	const avatarBodyTemplate = ({ fotoacreditacion }) => {
		// console.log(fotoacreditacion);
		if (!fotoacreditacion) return false;

		return (
			<AvatarGroup className='mb-3'>
				
						<Avatar
							image={fotoacreditacion}
							size='large'
							shape='circle'
							key={fotoacreditacion}
						/>
				
			</AvatarGroup>
		);
	};

	return (
		<div className='grid'>
			<div className='col-12'>
				<div className='card'>
					<Cargando cargando={cargando} />
					<Toast ref={toast} />
					{/* <BarraSuperior openNew={openNew} /> */}
					<TablaDatos datostabla={dataSet} titulo='Agendas'>
						{/* <Column
							field='nom_tag'
							header='Tipo'
							sortable
							body={tagBodyTemplate}
							headerStyle={{
								width: "10%",
								minWidth: "10rem",
							}}></Column> */}

							<Column
							field='fotoacreditacion'
							header='Foto'
							sortable
							body={avatarBodyTemplate}
							headerStyle={{
								width: "5%",
								minWidth: "10rem",
							}}></Column>


							<Column
							field='nombre'
							header='Acreditado'
							sortable
							// body={avatarBodyTemplate}
							headerStyle={{
								width: "55%",
								minWidth: "10rem",
							}}></Column>


						

						
						<Column
							header='Acciones'
							body={actionBodyTemplate}></Column>
					</TablaDatos>

					<Dialog
						visible={productDialog}
						style={{ width: "850px" }}
						header='Detalle Agenda'
						modal={true}
						// className='p-fluid'
						onHide={hideDialog}>
						<TabView>
							{listadia.map((item, index) => {
								// console.log(item);
								return (
									<TabPanel key={index} header={item.fec_age}>
										<DayCalendar citas= {item.citas}  />
									</TabPanel>
								);
							}
							)}


							
							{/* 
							// <TabPanel header='Header I'>
							// 	<div className='formgrid grid'>
							// 		<DayCalendar />
							// 	</div>
							// </TabPanel>
							<TabPanel header='Header II'>
								<DayCalendar />
							</TabPanel>
							<TabPanel header='Header III'>
								<DayCalendar />
							</TabPanel> */}
						</TabView>
					</Dialog>
				</div>
			</div>
		</div>
	);
};
