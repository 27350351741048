export const DayCalendar = ({ citas }) => {

	const hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]; // Array de 0 a 23
	// const minutes = Array.from({ length: 60 }, (_, i) => i); // Array de 0 a 59

	// const estilos = {
	// 	marginTop: "100px",
	// 	minHeight: "180px",
	// 	minWidth: "180px",
	// 	backgroundColor: "blue",
	// };

	// console.log(citas);

	const estilos2 = {
	  position: 'relative',
		marginTop: "80px",
		minHeight: "260px",
		backgraund: "yellow",
		minWidth: "100%",
		border: "1px solid green",
		backgroundColor: "rgb(184, 218, 186)",
	};

	let _platillacorta = false;
	
	return (
		<>
			<div className='diaCalendario'>
				<div className='horas'>
					{hours.map((hour, index) => (
						<div className='hora' key={index}>
							<h5>{hour}:00</h5>
						</div>
					))}
				</div>
				<div className='citas'>
					{citas.map((cita) => {
						const estilos2 = {
							position: "absolute",
							// with: '100%',
							marginTop: cita.int_ini_age - 360 + "px",

							minHeight:
								cita.int_fin_age - cita.int_ini_age + "px",
							// backgraund: "yellow",
							minWidth: "100%",
							border: "1px solid green",
							background: "rgb(184, 218, 186)",
						};

						if (cita.int_fin_age - cita.int_ini_age < 30) {
							_platillacorta = true;
						}
						//   console.log(estilos2);
						console.log();
						return (
							<div style={estilos2}>
								<div
									className='cardcitadetalle'
									key={cita.detadigo}>


										
									{/* <p>{cita.nom_tag} | {cita.nom_lug} | Horario: {cita.horario} | {cita.tit_age} </p> */}

									 {!_platillacorta ? <>
									<p>{cita.nom_tag}</p>
									<p>{cita.nom_lug}</p>
									<p>Horario: {cita.horario}  </p>
									<h5>{cita.tit_age}</h5></> : <>
									<p>{cita.nom_tag} | {cita.nom_lug} | Horario: {cita.horario}  {cita?.tit_age}</p>
									</>}
									
									
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};
